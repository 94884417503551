<template>
  <div id="app-mobile">
    <img :src="require('@/assets/share.png')" class="share-img" />
  </div>
</template>

<script>
export default {
  name: "share",
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
$room: 1020 / 375;
.share-img{
    width: 100vw;
    height: 100vh;
}
</style>
